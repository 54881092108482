@import url(https://fonts.googleapis.com/css?family=Lato:300,400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,400i,700);
body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  position: relative;
  color: #222222; }

a:link, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none; }

h1 {
  font-size: 32px;
  line-height: 32px; }

h2 {
  font-size: 24px;
  line-height: 24px;
  margin: 0;
  padding: 14px 0; }

h3 {
  font-size: 20px;
  line-height: 20px;
  padding: 14px 0;
  margin: 0; }

h4 {
  margin: 0;
  padding: 14px 0; }

header {
  width: 100%;
  height: 420px;
  background: url(/static/media/backgroundSphere.3b63490f.jpg) #1a1b1d 100% 0% no-repeat;
  background-size: auto 504px;
  color: #FFFFFF;
  padding-top: 149px; }

.header-wrapper {
  width: 480px;
  border: 1px solid #fff;
  padding: 28px;
  display: inline-block; }
  @media screen and (max-width: 480px) {
    .header-wrapper {
      width: 100%; } }
  .header-wrapper h1 {
    margin: 0;
    font-weight: 700; }
  .header-wrapper p {
    margin: 0;
    padding-bottom: 0;
    font-weight: 300; }

.navbar-custom {
  border: none;
  padding: 0 1em 0 0;
  background-color: #6a50a7; }
  .navbar-custom .navbar-brand {
    color: #FFFFFF;
    padding-left: 14px;
    padding-right: 14px;
    display: inline-block;
    font-size: 16px;
    padding: 12px 14px; }
    .navbar-custom .navbar-brand:hover, .navbar-custom .navbar-brand:focus {
      color: #FFFFFF;
      background-color: #8067b7; }
  .navbar-custom .navbar-text {
    color: #FFFFFF; }
  .navbar-custom .navbar-nav > li > a {
    color: #FFFFFF;
    padding: 14px 8px; }
    .navbar-custom .navbar-nav > li > a:hover, .navbar-custom .navbar-nav > li > a:focus {
      color: #FFFFFF;
      background-color: #8067b7; }
  .navbar-custom .navbar-nav > li > a.active, .navbar-custom .navbar-nav > li > a.active:hover, .navbar-custom .navbar-nav > li > a.active:focus {
    color: "";
    background-color: #3c3b3d; }
  .navbar-custom .navbar-nav > .disabled > a, .navbar-custom .navbar-nav > .disabled > a:hover, .navbar-custom .navbar-nav > .disabled > a:focus {
    color: "";
    background-color: ""; }
  .navbar-custom .navbar-toggle {
    border-color: #FFFFFF; }
    .navbar-custom .navbar-toggle:hover, .navbar-custom .navbar-toggle:focus {
      background-color: #8067b7; }
    .navbar-custom .navbar-toggle .icon-bar {
      background-color: #FFFFFF; }
  .navbar-custom .navbar-link {
    color: #FFFFFF; }
    .navbar-custom .navbar-link:hover {
      color: #FFFFFF; }
  .navbar-custom .btn-link {
    color: #FFFFFF; }
    .navbar-custom .btn-link:hover, .navbar-custom .btn-link:focus {
      color: #FFFFFF; }
    .navbar-custom .btn-link[disabled]:hover, .navbar-custom .btn-link[disabled]:focus,
    fieldset[disabled] .navbar-custom .btn-link:hover,
    fieldset[disabled] .navbar-custom .btn-link:focus {
      color: ""; }

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='#FFFFFF' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22' /></svg>"); }

.social {
  height: 100%;
  margin: 0; }
  .social > a:before {
    color: #ffffff;
    font-size: 18px;
    line-height: 49px; }

body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  position: relative;
  color: #222222; }

a:link, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none; }

h1 {
  font-size: 32px;
  line-height: 32px; }

h2 {
  font-size: 24px;
  line-height: 24px;
  margin: 0;
  padding: 14px 0; }

h3 {
  font-size: 20px;
  line-height: 20px;
  padding: 14px 0;
  margin: 0; }

h4 {
  margin: 0;
  padding: 14px 0; }

.clearFixHeader {
  margin-top: 48px; }

.portfolio-container .project-img {
  width: 100%;
  height: auto;
  margin-bottom: 1em; }
  @media screen and (max-width: 90%) {
    .portfolio-container .project-img {
      height: auto; } }

.portfolio-container .portfolio-item {
  padding: 2em; }

.portfolio-container .straw-light {
  background-color: #e8ce4d; }

.portfolio-container .plum-light {
  background-color: #8067b7; }

.portfolio-container .ruby-light {
  background-color: #d8334a; }

.portfolio-container .grey {
  background-color: #808080; }

.portfolio-container .text-light {
  font-weight: 300; }

.portfolio-container .categories_subtitle span:before {
  content: "/\A0";
  color: inherit;
  padding: 0 0.5em;
  display: inline-block; }

.portfolio-container .categories_subtitle span:first-child:before {
  content: "";
  padding: 0; }

body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  position: relative;
  color: #222222; }

a:link, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none; }

h1 {
  font-size: 32px;
  line-height: 32px; }

h2 {
  font-size: 24px;
  line-height: 24px;
  margin: 0;
  padding: 14px 0; }

h3 {
  font-size: 20px;
  line-height: 20px;
  padding: 14px 0;
  margin: 0; }

h4 {
  margin: 0;
  padding: 14px 0; }

@font-face {
  font-family: 'social';
  src: url("/fonts/icomoon.eot");
  src: url("/fonts/icomoon.eot") format("embedded-opentype"), url("/fonts/icomoon.ttf") format("truetype"), url("/fonts/icomoon.woff") format("woff"), url("/fonts/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'social' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-address:before {
  content: "\E901"; }

.icon-direction:before {
  content: "\E902"; }

.icon-email:before {
  content: "\E900"; }

.icon-link:before {
  content: "\E903"; }

.icon-mail:before {
  content: "\E904"; }

.icon-mobile2:before {
  content: "\E905"; }

.icon-phone:before {
  content: "\E906"; }

.icon-mobile:before {
  content: "\E958"; }

.icon-google-plus:before {
  content: "\EA8B"; }

.icon-facebook:before {
  content: "\EA90"; }

.icon-instagram:before {
  content: "\EA92"; }

.icon-twitter:before {
  content: "\EA96"; }

.icon-youtube:before {
  content: "\EA9E"; }

.icon-github:before {
  content: "\EAB0"; }

.icon-skype:before {
  content: "\EAC5"; }

.icon-linkedin:before {
  content: "\EACA"; }

.icon-pinterest:before {
  content: "\EAD2"; }

#connect {
  padding: 96px 0 2em 0;
  margin-bottom: 350px; }
  #connect .btnMail {
    margin-bottom: 2em;
    background-color: #6a50a7;
    border-color: #6a50a7;
    color: #FFFFFF; }
    #connect .btnMail:hover {
      background-color: #8067b7;
      border-color: #8067b7;
      color: #FFFFFF; }
  #connect a:before {
    color: #6a50a7;
    font-size: 1.5em;
    padding: 0 0.25em; }

footer small a {
  border-bottom: 1px solid #6a50a7; }

