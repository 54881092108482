@import url(https://fonts.googleapis.com/css?family=Lato:300,400,400i,700);
body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  position: relative;
  color: #222222; }

a:link, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none; }

h1 {
  font-size: 32px;
  line-height: 32px; }

h2 {
  font-size: 24px;
  line-height: 24px;
  margin: 0;
  padding: 14px 0; }

h3 {
  font-size: 20px;
  line-height: 20px;
  padding: 14px 0;
  margin: 0; }

h4 {
  margin: 0;
  padding: 14px 0; }

header {
  width: 100%;
  height: 420px;
  background: url("./images/backgroundSphere.jpg") #1a1b1d 100% 0% no-repeat;
  background-size: auto 504px;
  color: #FFFFFF;
  padding-top: 149px; }

.header-wrapper {
  width: 480px;
  border: 1px solid #fff;
  padding: 28px;
  display: inline-block; }
  @media screen and (max-width: 480px) {
    .header-wrapper {
      width: 100%; } }
  .header-wrapper h1 {
    margin: 0;
    font-weight: 700; }
  .header-wrapper p {
    margin: 0;
    padding-bottom: 0;
    font-weight: 300; }

.navbar-custom {
  border: none;
  padding: 0 1em 0 0;
  background-color: #6a50a7; }
  .navbar-custom .navbar-brand {
    color: #FFFFFF;
    padding-left: 14px;
    padding-right: 14px;
    display: inline-block;
    font-size: 16px;
    padding: 12px 14px; }
    .navbar-custom .navbar-brand:hover, .navbar-custom .navbar-brand:focus {
      color: #FFFFFF;
      background-color: #8067b7; }
  .navbar-custom .navbar-text {
    color: #FFFFFF; }
  .navbar-custom .navbar-nav > li > a {
    color: #FFFFFF;
    padding: 14px 8px; }
    .navbar-custom .navbar-nav > li > a:hover, .navbar-custom .navbar-nav > li > a:focus {
      color: #FFFFFF;
      background-color: #8067b7; }
  .navbar-custom .navbar-nav > li > a.active, .navbar-custom .navbar-nav > li > a.active:hover, .navbar-custom .navbar-nav > li > a.active:focus {
    color: "";
    background-color: #3c3b3d; }
  .navbar-custom .navbar-nav > .disabled > a, .navbar-custom .navbar-nav > .disabled > a:hover, .navbar-custom .navbar-nav > .disabled > a:focus {
    color: "";
    background-color: ""; }
  .navbar-custom .navbar-toggle {
    border-color: #FFFFFF; }
    .navbar-custom .navbar-toggle:hover, .navbar-custom .navbar-toggle:focus {
      background-color: #8067b7; }
    .navbar-custom .navbar-toggle .icon-bar {
      background-color: #FFFFFF; }
  .navbar-custom .navbar-link {
    color: #FFFFFF; }
    .navbar-custom .navbar-link:hover {
      color: #FFFFFF; }
  .navbar-custom .btn-link {
    color: #FFFFFF; }
    .navbar-custom .btn-link:hover, .navbar-custom .btn-link:focus {
      color: #FFFFFF; }
    .navbar-custom .btn-link[disabled]:hover, .navbar-custom .btn-link[disabled]:focus,
    fieldset[disabled] .navbar-custom .btn-link:hover,
    fieldset[disabled] .navbar-custom .btn-link:focus {
      color: ""; }

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='#FFFFFF' stroke-width='1' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22' /></svg>"); }

.social {
  height: 100%;
  margin: 0; }
  .social > a:before {
    color: #ffffff;
    font-size: 18px;
    line-height: 49px; }
