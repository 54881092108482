@import url(https://fonts.googleapis.com/css?family=Lato:300,400,400i,700);
body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  position: relative;
  color: #222222; }

a:link, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none; }

h1 {
  font-size: 32px;
  line-height: 32px; }

h2 {
  font-size: 24px;
  line-height: 24px;
  margin: 0;
  padding: 14px 0; }

h3 {
  font-size: 20px;
  line-height: 20px;
  padding: 14px 0;
  margin: 0; }

h4 {
  margin: 0;
  padding: 14px 0; }

@font-face {
  font-family: 'social';
  src: url("/fonts/icomoon.eot");
  src: url("/fonts/icomoon.eot") format("embedded-opentype"), url("/fonts/icomoon.ttf") format("truetype"), url("/fonts/icomoon.woff") format("woff"), url("/fonts/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'social' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-address:before {
  content: "\e901"; }

.icon-direction:before {
  content: "\e902"; }

.icon-email:before {
  content: "\e900"; }

.icon-link:before {
  content: "\e903"; }

.icon-mail:before {
  content: "\e904"; }

.icon-mobile2:before {
  content: "\e905"; }

.icon-phone:before {
  content: "\e906"; }

.icon-mobile:before {
  content: "\e958"; }

.icon-google-plus:before {
  content: "\ea8b"; }

.icon-facebook:before {
  content: "\ea90"; }

.icon-instagram:before {
  content: "\ea92"; }

.icon-twitter:before {
  content: "\ea96"; }

.icon-youtube:before {
  content: "\ea9e"; }

.icon-github:before {
  content: "\eab0"; }

.icon-skype:before {
  content: "\eac5"; }

.icon-linkedin:before {
  content: "\eaca"; }

.icon-pinterest:before {
  content: "\ead2"; }

#connect {
  padding: 96px 0 2em 0;
  margin-bottom: 350px; }
  #connect .btnMail {
    margin-bottom: 2em;
    background-color: #6a50a7;
    border-color: #6a50a7;
    color: #FFFFFF; }
    #connect .btnMail:hover {
      background-color: #8067b7;
      border-color: #8067b7;
      color: #FFFFFF; }
  #connect a:before {
    color: #6a50a7;
    font-size: 1.5em;
    padding: 0 0.25em; }

footer small a {
  border-bottom: 1px solid #6a50a7; }
