@import url(https://fonts.googleapis.com/css?family=Lato:300,400,400i,700);
body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  position: relative;
  color: #222222; }

a:link, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none; }

h1 {
  font-size: 32px;
  line-height: 32px; }

h2 {
  font-size: 24px;
  line-height: 24px;
  margin: 0;
  padding: 14px 0; }

h3 {
  font-size: 20px;
  line-height: 20px;
  padding: 14px 0;
  margin: 0; }

h4 {
  margin: 0;
  padding: 14px 0; }

.clearFixHeader {
  margin-top: 48px; }

.portfolio-container .project-img {
  width: 100%;
  height: auto;
  margin-bottom: 1em; }
  @media screen and (max-width: 90%) {
    .portfolio-container .project-img {
      height: auto; } }

.portfolio-container .portfolio-item {
  padding: 2em; }

.portfolio-container .straw-light {
  background-color: #e8ce4d; }

.portfolio-container .plum-light {
  background-color: #8067b7; }

.portfolio-container .ruby-light {
  background-color: #d8334a; }

.portfolio-container .grey {
  background-color: #808080; }

.portfolio-container .text-light {
  font-weight: 300; }

.portfolio-container .categories_subtitle span:before {
  content: "/\00a0";
  color: inherit;
  padding: 0 0.5em;
  display: inline-block; }

.portfolio-container .categories_subtitle span:first-child:before {
  content: "";
  padding: 0; }
